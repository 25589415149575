<template>
  <!-- 我的发票 -->
  <div class="customer_box">
    <div class="customer_search_box">
      <div class="search-item">
        <span>关键字</span>
        <el-input style="width: 190px;" clearable placeholder="发票号、订单编号" size="small" v-model="filterinfo.search"></el-input>
      </div>
      <div class="search-item">
        <span>开票日期</span>
        <el-date-picker
          style="width: 200px;"
          v-model="timeRange"
          type="daterange"
          size="small"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="btn_box">
        <el-button class="btn_item" type="primary" size="small" @click="getCustomerList(1)">查询</el-button>
        <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
      </div>
    </div>
    <div class="table_mode">
      <el-table :data="tableData" v-loading="loading" @sort-change="handleSortChange" stripe style="width: 100%">
        <el-table-column prop="amount" label="发票号" sortable="custom" min-width="110" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            <span style="color: #1A9DFF;">135454354545</span>
          </template>
        </el-table-column>
        <el-table-column prop="attr" label="发票类型" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="amount" label="关联订单号" sortable="custom" min-width="110" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            <span style="color: #1A9DFF;">135454354545</span>
          </template>
        </el-table-column>
        <el-table-column prop="attr" label="下单时间" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="address" label="操作 " width="130" align="center">
          <template slot-scope="scope">
            <div class="table_btn">
              <div class="btn_item" @click="dkxdClick(scope.row.memberId)">下载</div>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="nodata_box">
          <img src="@/assets/images/index/nodata.png"/>
          <span class="txt">暂无数据哦~</span>
        </div>
      </el-table>
    </div>
    <div class="pagination_box">
      <el-pagination
        background
        layout="prev, pager, next, sizes, total"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="filterinfo.pageSize"
        :current-page.sync="filterinfo.page"
        :total="total"
        @current-change="getCustomerList"
        @size-change="getCustomerList(1)">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { handlerTableData } from '@/utils/common'
export default {
  name: 'MyInvoice',
  data() {
    return {
      loading: false,
      total: 0,
      timeRange: [],
      filterinfo:{
        page:1,
        pageSize:10,
        search: '',
        timeRange: '',
      },
      tableData:[{
        amount: '',
        attr: 'sdaasdd'
      }],
    };
  },
  created() {

  },
  mounted(){
    this.getCustomerList()
  },
  methods: {
    // 点击重置
    resetClick(){
      this.filterinfo = {
        page:1,
        pageSize:10,
        search: '',
        timeRange: '',
      }
      this.getCustomerList()
    },
    // 获取我的客户列表
    getCustomerList(num){
      if(num==1){
        this.filterinfo.page = 1
      }
      let params = this.filterinfo
      if(this.timeRange&&this.timeRange.length){
        params.timeRange = this.timeRange[0]+'/'+this.timeRange[1]
      }else{
        params.timeRange = ''
      }
      // this.loading = true
      // this.$api.pcCustomerList(params).then((res) => {
      //   this.loading = false
      //   if (res.data.code === 200) {
      //     this.tableData = handlerTableData(res.data.data.list)
      //     // this.tableData = res.data.data.list
      //     this.options = res.data.data.shopType
      //     this.attributeOptions = res.data.data.attr
      //     this.total = res.data.data.total
      //   }
      // });
    },
    handleSortChange({ column, prop, order }) {
      if(order == 'ascending'){
        this.filterinfo.orderBy = "asc"
      }else{
        this.filterinfo.orderBy = "desc"
      }
      this.getCustomerList()
    }
  }
};
</script>
<style lang="scss" scoped>
.customer_box{
  position: relative;
  padding-top: 4px;
}
</style>